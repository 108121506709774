<template>
  <div :class="{'active-section': currentSectionSlug === slug}" class="accord">
    <div class="accord__head">
      <div @click="goToCatalog({sectionPath: slug})" class="accord__title">
        {{ title }}
      </div>
      <div @click="toggleSection(slug)" class="accord__title_icon">
        <i :class="{'la-angle-up': opened, 'la-angle-down': !opened}" class="la"></i>
      </div>
    </div>
    <transition
        name="accordion"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave">

      <div v-show="opened" class="accord__body">
        <ul class="accord-list">
          <template v-for="(item, key) in items">
            <li :class="{'active-category': currentCategorySlug === item.slug}" :key="key"><router-link :to="{name: 'Catalog', params: {'slug': slug, 'slug2': item.slug}}">{{item.name}}</router-link></li>
          </template>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "SectionMenu",
  data: () => ({}),
  props: {
    title: {
      type: String
    },
    slug: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    currentSectionSlug: {
      type: String,
      default: () => ''
    },
    currentCategorySlug: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    ...mapActions('catalog', ['goToCatalog', 'toggleSection']),
    beforeEnter: function(el) {
      el.style.height = '0';
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave: function(el) {
      el.style.height = '0';
    }
  },
  computed: {
    ...mapGetters('catalog', ['isSectionOpened']),
    opened() {
      return this.isSectionOpened(this.slug);
    }
  },
}
</script>

<style lang="sass" scoped>
.accord
  padding: 15px 10px
  color: #444
  font-weight: bold

  &__head
    display: flex
    justify-content: space-between
    flex-direction: row

    &:hover
      color: #6b6b6b

  &__title
    font-size: 16px
    line-height: 1.5
    text-align: left
    cursor: pointer

  &.active-section
    .accord__title
      color: #e5173f

  &__title_icon
    cursor: pointer
    //margin-left: 100px

    i
      font-size: 16px

  &__body
    margin-top: 10px

  &__body_visible
    display: block

.accord-list
  padding-left: 20px
  list-style-type: none
  margin: 0
  font-size: 14px

  li
    padding: 7.5px 0
    cursor: pointer

    &:hover
      color: #6b6b6b

    &.active-category
      border-bottom: 2px
      border-color: #e5173f


  li.active-category
    color: #e5173f



.accordion-enter-active, .accordion-leave-active
  will-change: height, opacity
  transition: height 0.3s ease, opacity 0.3s ease
  overflow: hidden
</style>

<template>
  <div class="container-fluid bg-light-to-white">
    <div class="row">
      <div class="container">
        <div class="row o-hidden">
          <div class="tab-wrap d-block d-md-none">
            <ul class="nav nav-tabs ">
              <li class="nav-item">
                <a class="nav-link active" id="category-tab" href="#categories" data-toggle="tab"
                   role="tab">
                  <img src="@/assets/images/star.svg" alt="star"/>
                  Категории
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="filter-tab" href="#filter" data-toggle="tab" role="tab">
                  <img src="@/assets/images/settings.svg" alt="settings"/>
                  Фильтр
                </a>
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div role="tabpanel" class="tab-pane active" id="categories">
                <ul class="left-menu theme1" v-if="category.childrens">
                  <template v-for="(section, key) in sections">
                    <section-accordion
                        v-if="section.name && section.slug && section.childrens && section.childrens.length > 0"
                        :current-section-slug="slug" :current-category-slug="slug2" :key="key"
                        :title="section.name"
                        :slug="section.slug" :items="section.childrens"/>
                  </template>
                </ul>
              </div>
              <div role="tabpanel" class="tab-pane active" id="filter">
                <h4 class="sidebar-title">Поиск</h4>
                <div class="widget">
                  <div class="d-flex justify-content-between">
                    <input v-on:keyup.enter="makeSearch" v-model="search" type="text"
                           class="name-field form-control" name="min" id="filter-price-min"
                           placeholder="Название"/>
                  </div>
                  <div class="mt-3 pt-3">
                    <button @click="makeSearch" style="margin-left:10px"
                            class="btn btn-trans btn-s">
                      Искать
                    </button>
                    &nbsp;
                    <button @click="cleanSearch" class="btn btn-trans btn-s">Сбросить</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- Products-->
          <div class="col-xl-9 col-lg-8 col-md-8 order-2">
            <div class="product-grid">
              <div v-if="slug && slug === 'materialy-dlya-oformleniya-sto-i-rtt'" class="section-header">
                <p>
                  На <a href="https://spec.lukoil-masla.ru/spec/" target="_blank">спецресурсе</a> для дилеров
                  в разделах «Стандарты для розничных торговых точек» и «Стандарты для станций технического
                  обслуживания» выложены
                  дополнительные материалы (исходники макетов) для оформления РТТ/СТО, которые дилер может произвести
                  самостоятельно.
                </p>
                <p>
                  Полная стоимость производства данных маркетинговых материалов может быть учтена в качестве
                  использования маркетингового
                  ретро бонуса и списана из личного кабинета дилера в ЛУКМАРКЕТ при выполнении следующих условий:
                </p>
                <ul type="disc">
                  <li>Выполнение макета согласно рекомендациям по оформлению РТТ/СТО</li>
                  <li>Согласование макета с маркетингом ЛЛК (Солтанова Ксения <a
                      href="mailto:Kseniya.Soltanova@lukoil.com">Kseniya.Soltanova@lukoil.com</a>)
                  </li>
                  <li>
                    Изготовление материала, согласно техническим характеристикам, указанным в рекомендациях по
                    оформлению РТТ/СТО;
                  </li>
                  <li>Предоставление фотографий изготовленных маркетинговых материалов;</li>
                  <li>
                    Предоставление документов, подтверждающих понесенные расходы (счет, платежное поручение, акт, накладная).
                  </li>
                </ul>
                <p>
                    Для учета стоимости самостоятельно произведенных материалов
                    в счет маркетингового ретро бонуса необходимо оформить заявку в личном кабинете,
                    раздел <router-link to="/requests-write-down-points">«Заявки на списание»</router-link>.
                </p>
              </div>
              <div class="col">
                <h2 class="title-b text-uppercase"></h2>
                <div id="productsContainer" class="row mt-5 d-flex">
                    <template v-for="product in products">
                        <template v-if="(product.name === 'test' && +accountInfo.dealerUniqueId === 789654123) || product.name !== 'test'">
                            <div :key="product.id" class="col-12 col-md-6 col-xl-4">
                                <product-item :product="product"/>
                            </div>
                        </template>
                    </template>
                </div>
                <nav class="mt-5 d-flex justify-content-center" v-if="total > 6">
                  <paginate :items="items" @changePage="onChangePage"/>
                </nav>
              </div>
            </div>
          </div>
          <!-- Sidebar -->
          <div class="col-xl-3 col-lg-4 col-md-4 order-1 d-none d-md-block  bg-light">
            <aside class="sidebar">
              <h4 class="sidebar-title">Поиск</h4>
              <div class="widget">
                <div class="d-flex justify-content-between">
                  <input v-on:keyup.enter="makeSearch" v-model="search" type="text"
                         class="name-field form-control" name="min" id="price-min"
                         placeholder="Название"/>
                </div>
                <div class="mt-3 pt-3">
                  <button @click="makeSearch" style="margin-left:10px" class="btn btn-trans btn-s">
                    Искать
                  </button>
                  &nbsp;
                  <button @click="cleanSearch" class="btn btn-trans btn-s">Сбросить</button>
                </div>
              </div>
              <ul class="left-menu theme1" v-if="category.childrens">
                <template v-for="(section, key) in sections">
                  <section-accordion
                      v-if="section.name && section.slug && section.childrens && section.childrens.length > 0"
                      :current-section-slug="slug" :current-category-slug="slug2" :key="key"
                      :title="section.name"
                      :slug="section.slug" :items="section.childrens"/>
                </template>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/js/src/tab';
import {get} from '@/lib/axios';
import ProductItem from "../../components/catalog/ProductItem";
import Paginate from "../../components/Paginate";
import SectionAccordion from "../../components/catalog/SectionMenu";
import {setLoading, removeLoading} from "@/lib/loading";
import {mapGetters} from "vuex";

export default {
  name: "Catalog",
  components: {ProductItem, Paginate, SectionAccordion},
  created() {
    this.slug = this.$route.params.slug
    this.slug2 = this.$route.params.slug2

    if (this.$route.query.name) {
      this.search = this.$route.query.name;
    } else {
      this.search = '';
    }

    this.fetchData()
  },
  beforeRouteUpdate(to, from, next) {
    this.slug = to.params.slug
    this.slug2 = to.params.slug2

    if (to.query.name) {
      this.search = to.query.name;
    } else {
      this.search = '';
    }

    this.fetchData()
    next()
  },
  data: () => ({
    currentPage: 0,
    search: '',
    slug: null,
    slug2: null,
    sections: [],
    category: {},
    products: [],
    total: 0,
    section: {},
    items: 0,
    pageOfItems: []
  }),
  methods: {
    async fetchData() {
      setLoading();

      await get(this.currentApiUrl, {page: this.currentPage, name: this.search}).then(response => {
        this.sections = response.data.data.sections
        this.category = response.data.data.category
        this.products = response.data.data.products
        this.section = response.data.data.section
        this.total = response.data.data.total
        this.items = [...Array(response.data.data.total).keys()].map(i => ({
          id: (i + 1),
          name: 'Item ' + (i + 1)
        }));

        removeLoading();

        if (this.category.childrens.length < 1) {
          get('categories/' + this.$route.params.slug).then(response => {
            this.category = response.data.data.category
          });
        }
      })
    },
    onChangePage(pageOfItems, pager) {
      this.slug = this.$route.params.slug
      this.slug2 = this.$route.params.slug2
      this.pageOfItems = pageOfItems
      this.currentPage = pager.currentPage - 1;

      this.fetchData()
    },
    makeSearch() {
      this.currentPage = 0;
      this.fetchData();
      this.changeSearchInUrl(this.search);
    },
    cleanSearch() {
      this.search = '';
      this.currentPage = 0;
      this.fetchData();
      this.changeSearchInUrl(this.search);
    },
    changeSearchInUrl(search) {
      let params = {slug: this.slug};
      let query = {};

      if (this.slug2) {
        params['slug2'] = this.slug2;
      }

      if (search) {
        query['name'] = search;
      }

      this.$router.push({
        name: 'Catalog',
        params,
        query
      })
    }
  },
  computed: {
      ...mapGetters( 'account', [ 'accountInfo' ] ),
    currentApiUrl() {
      let url = '/categories/' + this.slug;

      if (this.slug2) {
        url += '/' + this.slug2;
      }

      return url;
    }
  },
  metaInfo() {
    let title = ''

    if (this.category) {
      title = this.category.name

      if (this.category.childrens) {
        for (let i = 0; i < this.category.childrens.length; i++) {
          if (this.slug2 === this.category.childrens[i].slug) {
            title = this.category.childrens[i].name
          }
        }
      }
    }
    title += ' - ЛУКМАРКЕТ'

    return {
      title: title,
    }
  },
}
</script>

<style scoped>
.priceRange .noUi-target {
  height: 2px;
  border-radius: 0;
}

.mobile-transform .noUi-base .noUi-handle.noUi-handle-lower {
  right: -9px !important;
}

.mobile-transform .noUi-base .noUi-handle.noUi-handle-upper {
  right: 0 !important;
}

.transform .noUi-base .noUi-handle.noUi-handle-lower {
  right: -8px !important;
}

.transform .noUi-base .noUi-handle.noUi-handle-upper {
  right: -6px !important;
}

ul.left-menu a:link,
ul.left-menu a:visited,
ul.left-menu a {
  text-decoration: none;
  color: #444;
}

ul.left-menu {
  list-style: none;
  padding-left: 0;
  margin-top: 25px;
}

ul.left-menu ul {
  padding-left: 0;
  list-style: none;
}

ul.left-menu li {
  line-height: 1.2em;
  cursor: pointer;
}

ul.left-menu li a:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

ul.left-menu ul {
  margin: 20px 0;
}

ul.left-menu li a {
  display: block;
  margin-left: 0;
  padding: 1em;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
}

/* Indentation Theme */
ul.left-menu.theme1 li li a {
  font-weight: normal;
  padding: 5px 0;
}

ul.left-menu li:last-child {
  border-bottom: none;
}

input#price-min[readonly], input#price-max[readonly] {
  background-color: white;
}

@media (max-width: 767px) {
  .container-fluid.bg-light-to-white {
    background-image: none;
  }

  .tab-wrap {
    width: 100%;
  }

  .nav-tabs .nav-item {
    text-align: center;
    width: 50%;
    font-size: 18px;
    float: left;
    margin: 0;
    margin-bottom: -3px;
  }

  .nav-tabs .nav-item img {
    width: 20px;
    margin-right: 20px;
  }

  .tab-pane {
    padding: 40px;
  }

  .tab-content > .active {
    background-color: #eff1f6;
  }

  .nav-tabs {
    border: none;
  }

  .nav-tabs .nav-link {
    padding: 20px;
  }

  .nav-tabs .nav-link:hover {
    border-color: transparent;
    outline: none;
  }

  .nav-tabs .nav-link.active {
    background-color: #eff1f6;
    border: none;
  }

  .price-field {
    border: solid 1px #d0d0d0 !important;
    background: #eff1f6;
  }

  .name-field {
    border: solid 1px #d0d0d0 !important;
    background: #eff1f6;
    width: 250px !important;
  }
}

.section-header {
  margin-bottom: 10px;
  font-size: 16px;
}

.section-header a {
  color: rgb(0, 120, 212);
}

.section-header > p {
  margin-bottom: 0.25rem;
}

.section-header > ul {
  margin: 0;
  margin-left: 17px;
}

.section-header > p, .section-header > ul {
  text-align: justify;
}

/*.section-header > ul > li {*/
/*  font-weight: bold;*/
/*}*/
</style>

<template>
    <ul v-if="pager.pages && pager.pages.length" class="pagination justify-content-center" :style="ulStyles">
        <li class="page-item previous" :class="{'disabled': pager.currentPage === 1}" :style="liStyles">
            <a class="page-link" @click="setPage(pager.currentPage - 1)" :style="aStyles">Назад</a>
        </li>
        <li v-for="page in pager.pages" :key="page" class="page-item page-number" :class="{'active': pager.currentPage === page}" :style="liStyles">
            <a class="page-link" @click="setPage(page)" :style="aStyles">{{page}}</a>
        </li>
        <li class="page-item next" :class="{'disabled': pager.currentPage === pager.totalPages}" :style="liStyles">
            <a class="page-link" @click="setPage(pager.currentPage + 1)" :style="aStyles">Вперёд</a>
        </li>
    </ul>
</template>

<script>
import paginate from 'jw-paginate';

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        initialPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 6
        },
        maxPages: {
            type: Number,
            default: 5
        },
    },
    data () {
        return {
            pager: {},
            ulStyles: {},
            liStyles: {},
            aStyles: {}
        }
    },
    created () {
        if (!this.$listeners.changePage) {
            throw 'Missing required event listener: "changePage"';
        }

        // set page if items array isn't empty
        if (this.items && this.items.length) {
            this.setPage(this.initialPage);
        }
    },
    methods: {
        setPage(page) {
            let { items, pageSize, maxPages } = this;

            // get new pager object for specified page
            let pager = paginate(items.length, page, pageSize, maxPages);

            // get new page of items from items array
            let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

            // update pager
            this.pager = pager;

            // emit change page event to parent component
            this.$emit('changePage', pageOfItems, pager);
        }
    }
}
</script>

<style scoped>
.page-item .page-link {
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #4a4a4a;
    position: relative;
    padding: 5px 10px;
}
.page-item .page-link:hover {
    background-color: inherit;
    color: black;
}
.page-item.active .page-link {
    border: 1px solid #eff1f6;
    border-radius: 0;
    background: inherit;
}

.page-item:first-child .page-link:before,
.page-item:last-child .page-link:after{
    content: '';
    background-image: url("../assets/images/arrow_down.svg");
    transform-origin: center;
    display: block;
    position: absolute;
    width: 10px;
    height: 7px;
    top: 10px;

}

.page-item:first-child .page-link:before {
    left: -15px;
    transform: rotate(90deg);
}
.page-item:last-child .page-link:after {
    right: -15px;
    transform: rotate(-90deg);
}

a.disabled {
    pointer-events: none;
}
</style>

<template>
    <div :class="`product-badges ${position}`">
        <span v-if="isLongTermProduction" class="product-badges__long-terms">
            Длительное производство
        </span>
        <span v-if="isLimitedSeries" class="product-badges__limited">
            Количество товара ограничено
        </span>
    </div>
</template>

<script>
export default {
    name: 'ProductBadges',
    props: {
        isLongTermProduction: {
            type: Boolean,
            default: false,
        },
        isLimitedSeries: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: 'top-left' // top-left, top-right, bottom-left, bottom-right
        }
    }
}
</script>

<style lang="sass" scoped>
.product-badges
    position: absolute
    display: flex
    flex-direction: column
    align-items: baseline
    gap: 5px
    z-index: 1
    &.top-left
        top: 0
        left: 0
    &.top-right
        top: 0
        right: 0
    &.bottom-left
        bottom: 0
        left: 0
    &.bottom-right
        bottom: 0
        right: 0
    &__long-terms, &__limited
        border-radius: 4px
        padding: 4px 6px
        color: #ffffff
        font-size: 15px
        font-weight: bold
    &__long-terms
        background-color: #34BFA3
    &__limited
        background-color: #FF8C68
</style>
